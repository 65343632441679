import {
  extendTheme,
  StyleFunctionProps,
  theme as chakraTheme,
  withDefaultColorScheme
} from '@chakra-ui/react'

export const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'green'
  }),
  {
    config: {
      cssVarPrefix: 'gx'
    },
    components: {
      Button: {
        variants: {
          solid: ({ colorScheme }: StyleFunctionProps) => {
            return {
              bgColor: `${colorScheme}.300`,
              fontWeight: 'bold',
              _hover: {
                bgColor: `${colorScheme}.400`,
                textDecoration: 'none'
              }
            }
          },
          outline: ({ colorScheme }: StyleFunctionProps) => {
            return {
              borderColor: `${colorScheme}.300`,
              color: `${colorScheme}.300`,
              _hover: {
                borderColor: `${colorScheme}.500`,
                textDecoration: 'none'
              }
            }
          },
          ghost: ({ colorScheme }: StyleFunctionProps) => {
            return {
              // bgColor: `${colorScheme}.300`,
              _hover: {
                // bgColor: `${colorScheme}.400`,
                textDecoration: 'none'
              }
            }
          },
          link: ({ colorScheme }: StyleFunctionProps) => {
            return {
              // bgColor: `${colorScheme}.300`,
              _hover: {
                // bgColor: `${colorScheme}.400`,
                // textDecoration: 'none'
              }
            }
          }
        },
        sizes: {
          xl: {
            px: '40px',
            py: '17px',
            borderRadius: '6px',
            fontSize: 'lg',
            height: '61px',
            fontWeight: 'semibold'
          },
          md: {
            px: '24px',
            py: '12px',
            borderRadius: '6px',
            fontSize: 'sm',
            height: '48px',
            fontWeight: 'semibold'
          },
          sm: {
            px: '16px',
            py: '10px',
            borderRadius: '6px',
            fontSize: 'sm',
            height: '41px',
            fontWeight: 'semibold'
          }
        }
      },
      Stepper: {
        baseStyle: {
          indicator: {
            width: '3rem',
            height: '3rem',
            borderColor: 'grey.100',
            color: 'grey.100',
            fontWeight: 'semibold',
            cursor: 'pointer'
          },
          separator: {
            background: 'grey.100'
          },
          title: {
            color: 'grey.100',
            fontWeight: 'semibold'
          }
        }
      },
      Tabs: {
        variants: {
          'soft-rounded': {
            tab: {
              fontSize: 'sm',
              fontWeight: 'normal',
              borderRadius: '6px',
              borderWidth: 1,
              borderColor: 'grey.500',
              whiteSpace: 'nowrap',
              _selected: {
                borderColor: 'green.rgba200',
                bg: 'green.rgba100',
                color: 'green.400'
              }
            },
            tablist: {
              gap: '12px',
              flexWrap: 'wrap'
            },
            tabpanels: {},
            tabpanel: {}
          }
        }
      }
    },
    colors: {
      green: {
        rgba100: 'rgba(77, 249, 174, 0.24)',
        rgba200: 'rgba(77, 249, 174, 0.15)',
        rgba300: 'rgba(77, 249, 174, 0.10)',
        200: '#7CF9C2',
        300: '#4DF9AE',
        400: '#47E7A2',
        500: '#3FC7A9',
        600: '#2FAD73'
      },
      grey: {
        rgba300: 'rgba(141, 136, 181, 0.20)',
        25: '#716E84',
        50: '#726D8F',
        100: '#8D88B5',
        200: '#726C97',
        300: '#58537A',
        400: '#403C5D',
        500: '#322F4D',
        600: '#2A2740',
        900: '#222034',
        950: '#1B1929'
      },
      pink: {
        300: '#F86078'
      },
      yellow: {
        rgba100: 'rgba(300, 550, 18, 0.24)',
        300: '#CCC888',
        400: '#BCC046'
      },
      greenLight: {
        400: '#7CF9C2',
        500: '#47E7A2'
      }
    },
    fonts: {
      heading: `'Inter', sans-serif`,
      body: `'Inter', sans-serif`,
      input: `'Inter', sans-serif`
    },
    fontSizes: {
      xs: '0.75rem', //12px
      sm: '0.875rem', //14px
      md: '1rem', //16px
      lg: '1.125rem', //18px
      xl: '1.25rem', //20px
      '2xl': '1.5rem', //24px
      '3xl': '2rem', //32px
      '4xl': '2.5rem',
      '5xl': '3rem',
      '6xl': '3.5rem',
      '7xl': '4rem',
      '8xl': '5rem',
      '9xl': '8rem'
    },
    textStyles: {
      inter: {
        fontFamily: `'Inter', sans-serif`
      },
      poppins: {
        fontFamily: `'Poppins', sans-serif`
      },
      space: {
        fontFamily: `'Space Grotesk', sans-serif`
      },
      sora: {
        fontFamily: `'Sora', sans-serif`
      }
    },
    fontWeights: {
      normal: 400,
      semibold: 600,
      bold: 700
    },
    styles: {
      global: {
        body: {
          bg: 'grey.900',
          color: 'white'
        }
      }
    }
  }
)
